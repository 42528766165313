// @flow
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import TopBar from 'containers/header/TopBarContainer';
import MenuContainer from 'containers/header/MenuContainer';
import HeaderMobile from 'containers/header/HeaderMobileContainer';
import { setBodyStyle, resetBodyStyle } from 'utils/bodyUtils';
import { dynamicClassName } from 'utils/dynamicClassName';
import HeaderFindClubContainer from 'containers/header/HeaderFindClubContainer';
import { headerFilterClubWithlistPaths } from 'constants/menuConstants';

type Props = {
  location: any
};

type ComponentState = {
  isMobileMenuOpen: boolean
};

class Header extends PureComponent<Props, ComponentState> {
  state: ComponentState = {
    isMobileMenuOpen: false
  };

  onToggleMenu = () => {
    if (this.state.isMobileMenuOpen) this.onCloseMenu();
    else this.onOpenMenu();
  };

  onOpenMenu = () => {
    setBodyStyle({
      position: 'fixed',
      width: '100%',
      top: 0
    });
    this.setState({ isMobileMenuOpen: true });
  };

  onCloseMenu = () => {
    resetBodyStyle();
    this.setState({ isMobileMenuOpen: false });
  };

  render() {
    const { isMobileMenuOpen } = this.state;
    const classNamesHeaderWrapper = dynamicClassName('header__wrapper');
    isMobileMenuOpen && classNamesHeaderWrapper.add('is-visible');
    return (
      <>
        <header className="header">
          <div className={classNamesHeaderWrapper.build()}>
            <TopBar />
            <MenuContainer onCloseMenu={this.onCloseMenu} />
          </div>
          <HeaderMobile isMenuOpen={isMobileMenuOpen} onToggleMenu={this.onToggleMenu} onCloseMenu={this.onCloseMenu} />
          {headerFilterClubWithlistPaths.includes(this.props.location.pathname) && <HeaderFindClubContainer />}
        </header>
      </>
    );
  }
}

export default withRouter(Header);

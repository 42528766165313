// @flow

export const FILTER_OPTIONS_SET_QUERY = 'FILTER_OPTIONS_SET_QUERY';
export const FILTER_OPTIONS_SET_COMPETITON = 'FILTER_OPTIONS_SET_COMPETITON';
export const FILTER_OPTIONS_SET_PRACTICE = 'FILTER_OPTIONS_SET_PRACTICE';
export const FILTER_OPTIONS_SET_DISTANCE = 'FILTER_OPTIONS_SET_DISTANCE';
export const REINITIALISE_SEARCH = 'REINITIALISE_SEARCH';
export const FILTER_OPTIONS_ACTIVATE_DISTANCE = 'FILTER_OPTIONS_ACTIVATE_DISTANCE';

export type FILTER_OPTIONS_SET_QUERY_ACTION = {
  type: 'FILTER_OPTIONS_SET_QUERY',
  payload: {
    query: string
  }
};

export type FILTER_OPTIONS_SET_COMPETITION_ACTION = {
  type: 'FILTER_OPTIONS_SET_COMPETITON',
  payload: {
    competitions: Array<string>
  }
};

export type FILTER_OPTIONS_SET_PRACTICE_ACTION = {
  type: 'FILTER_OPTIONS_SET_PRACTICE',
  payload: {
    practices: Array<string>
  }
};

export type FILTER_OPTIONS_SET_DISTANCE_ACTION = {
  type: 'FILTER_OPTIONS_SET_DISTANCE',
  payload: {
    distance: string
  }
};

export type REINITIALISE_SEARCH_ACTION = {
  type: 'REINITIALISE_SEARCH',
};

export type FILTER_OPTIONS_ACTIVATE_DISTANCE_ACTION = {
  type: 'FILTER_OPTIONS_ACTIVATE_DISTANCE',
  payload: {
    activateDistance: boolean
  }
};

export type Action =
  | FILTER_OPTIONS_SET_QUERY_ACTION
  | FILTER_OPTIONS_SET_COMPETITION_ACTION
  | FILTER_OPTIONS_SET_PRACTICE_ACTION
  | FILTER_OPTIONS_SET_DISTANCE_ACTION
  | REINITIALISE_SEARCH_ACTION
  | FILTER_OPTIONS_ACTIVATE_DISTANCE_ACTION;

export function setQuery(query: string): FILTER_OPTIONS_SET_QUERY_ACTION {
  return {
    type: FILTER_OPTIONS_SET_QUERY,
    payload: { query }
  };
}

export function setCompetitions(competitions: Array<string>): FILTER_OPTIONS_SET_COMPETITION_ACTION {
  return {
    type: FILTER_OPTIONS_SET_COMPETITON,
    payload: { competitions }
  };
}

export function setPractices(practices: Array<string>): FILTER_OPTIONS_SET_PRACTICE_ACTION {
  return {
    type: FILTER_OPTIONS_SET_PRACTICE,
    payload: { practices }
  };
}

export function setDistance(distance: string): FILTER_OPTIONS_SET_DISTANCE_ACTION {
  return {
    type: FILTER_OPTIONS_SET_DISTANCE,
    payload: { distance }
  };
}

export function reinitialiseSearch(): REINITIALISE_SEARCH_ACTION {
  return {
    type: REINITIALISE_SEARCH
  };
}

export function activateDistance(): FILTER_OPTIONS_ACTIVATE_DISTANCE_ACTION {
  return {
    type: FILTER_OPTIONS_ACTIVATE_DISTANCE,
    payload: {
      activateDistance: true
    }
  };
}

// @flow

import type { PageListType } from 'types/PageList';
import type { PageNodeType } from 'types/PageNode';
import type { PageDetailType } from 'types/PageDetail';

import { loadingPageNode } from 'types/PageNode';

export const FETCH_PAGE_LIST_START = 'FETCH_PAGE_LIST_START';
export const FETCH_PAGE_LIST_SUCCESS = 'FETCH_PAGE_LIST_SUCCESS';
export const FETCH_PAGE_LIST_FAILURE = 'FETCH_PAGE_LIST_FAILURE';

export const FETCH_PAGE_NODE_START = 'FETCH_PAGE_NODE_START';
export const FETCH_PAGE_NODE_SUCCESS = 'FETCH_PAGE_NODE_SUCCESS';
export const FETCH_PAGE_NODE_FAILURE = 'FETCH_PAGE_NODE_FAILURE';

export const FETCH_PAGE_DETAIL_START = 'FETCH_PAGE_DETAIL_START';
export const FETCH_PAGE_DETAIL_SUCCESS = 'FETCH_PAGE_DETAIL_SUCCESS';
export const FETCH_PAGE_DETAIL_FAILURE = 'FETCH_PAGE_DETAIL_FAILURE';

export const FETCH_UNKNOWN = 'FETCH_UNKNOWN';

export type FETCH_PAGE_NODE_START_ACTION = {
  type: 'FETCH_PAGE_NODE_START',
  payload: {
    slug: string,
    pageNode: PageNodeType
  }
};

export type FETCH_PAGE_NODE_FAILURE_ACTION = {
  type: 'FETCH_PAGE_NODE_FAILURE'
};

export type FETCH_PAGE_NODE_SUCCESS_ACTION = {
  type: 'FETCH_PAGE_NODE_SUCCESS',
  payload: {
    pageNode: PageNodeType
  }
};

export type FETCH_PAGE_LIST_START_ACTION = {
  type: 'FETCH_PAGE_LIST_START',
  payload: {
    slug: string
  }
};

export type FETCH_PAGE_LIST_FAILURE_ACTION = {
  type: 'FETCH_PAGE_LIST_FAILURE'
};

export type FETCH_PAGE_LIST_SUCCESS_ACTION = {
  type: 'FETCH_PAGE_LIST_SUCCESS',
  payload: {
    pageList: PageListType
  }
};

export type FETCH_PAGE_DETAIL_START_ACTION = {
  type: 'FETCH_PAGE_DETAIL_START',
  payload: {
    slug: string
  }
};

export type FETCH_PAGE_DETAIL_FAILURE_ACTION = {
  type: 'FETCH_PAGE_DETAIL_FAILURE'
};

export type FETCH_PAGE_DETAIL_SUCCESS_ACTION = {
  type: 'FETCH_PAGE_DETAIL_SUCCESS',
  payload: {
    pageDetail: PageDetailType,
    analytics: boolean
  }
};

export type FETCH_UNKNOWN_ACTION = {
  type: 'FETCH_UNKNOWN'
};

export type Action =
  | FETCH_PAGE_LIST_START_ACTION
  | FETCH_PAGE_LIST_FAILURE_ACTION
  | FETCH_PAGE_LIST_SUCCESS_ACTION
  | FETCH_PAGE_NODE_START_ACTION
  | FETCH_PAGE_NODE_FAILURE_ACTION
  | FETCH_PAGE_NODE_SUCCESS_ACTION
  | FETCH_PAGE_DETAIL_START_ACTION
  | FETCH_PAGE_DETAIL_FAILURE_ACTION
  | FETCH_PAGE_DETAIL_SUCCESS_ACTION
  | FETCH_UNKNOWN_ACTION;

export function fetchPageList(slug: string): FETCH_PAGE_LIST_START_ACTION {
  return {
    type: FETCH_PAGE_LIST_START,
    payload: { slug }
  };
}

export function pageListFetched(pageList: PageListType): FETCH_PAGE_LIST_SUCCESS_ACTION {
  return {
    type: FETCH_PAGE_LIST_SUCCESS,
    payload: { pageList }
  };
}

export function pageListFetchError(): FETCH_PAGE_LIST_FAILURE_ACTION {
  return {
    type: FETCH_PAGE_LIST_FAILURE
  };
}

export function fetchPageNode(slug: string): FETCH_PAGE_NODE_START_ACTION {
  return {
    type: FETCH_PAGE_NODE_START,
    payload: {
      slug,
      pageNode: loadingPageNode
    }
  };
}

export function pageNodeFetched(pageNode: PageNodeType): FETCH_PAGE_NODE_SUCCESS_ACTION {
  return {
    type: FETCH_PAGE_NODE_SUCCESS,
    payload: { pageNode }
  };
}

export function pageNodeFetchError(): FETCH_PAGE_NODE_FAILURE_ACTION {
  return {
    type: FETCH_PAGE_NODE_FAILURE
  };
}

export function fetchPageDetail(slug: string): FETCH_PAGE_DETAIL_START_ACTION {
  return {
    type: FETCH_PAGE_DETAIL_START,
    payload: { slug }
  };
}

export function pageDetailFetched(pageDetail: PageDetailType, analytics?: boolean): FETCH_PAGE_DETAIL_SUCCESS_ACTION {
  return {
    type: FETCH_PAGE_DETAIL_SUCCESS,
    payload: {
      pageDetail,
      analytics: analytics ? analytics : false
    }
  };
}

export function pageDetailFetchError(): FETCH_PAGE_DETAIL_FAILURE_ACTION {
  return {
    type: FETCH_PAGE_DETAIL_FAILURE
  };
}

export function FetchUnknown(): FETCH_UNKNOWN_ACTION {
  return {
    type: FETCH_UNKNOWN
  };
}

// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import { getPlaceholder } from 'utils/assetsUtils';

export const nullArticle = {
  slug: '',
  date: new Date(),
  label: {
    name: '',
    slug: ''
  },
  caption: '',
  title: 'Default title',
  srcset: {},
  illustration: getPlaceholder(),
  illustration_video: [],
  blocs: [],
  tags: [],
  twitter: 0,
  facebook: 0,
  ffr_url_canonical: '',
  sidebar: [],
};

export const nullLoadingArticle = {
  ...nullArticle,
  title: 'Chargement de l\'article',
  illustration: getPlaceholder(),
  blocs: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Chargement du contenu de l\'article...'
        }
      ]
    }
  ],
};

export const nullFailureArticle = {
  ...nullArticle,
  title: 'Oups',
  illustration: getPlaceholder(),
  blocs: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Un problème est survenue, merci de réactualiser la page.'
        }
      ]
    }
  ],
};

export const nullCategory = {
  id: 0,
  name: '',
  slug: ''
};

export const nullCategoryArticle = {
  id: 0,
  name: '',
  slug: '',
  items: []
};

export type SourceSetType = {
  [key: string]: {
    src: string,
    width: number,
    height: number,
  },
};

export type ArticleType = {
  slug: string,
  date: Date | string,
  label: {
    name: string,
    slug: string
  },
  caption: string,
  title: string,
  srcset: SourceSetType,
  illustration: string,
  illustration_video: BlocContent[],
  blocs: BlocContent[],
  tags: {
    id: number,
    name: string
  }[],
  twitter: number,
  facebook: number,
  ffr_url_canonical: string,
  sidebar: BlocContent[],
};

export type RawArticleType = {
  id: number,
  date: string,
  data_gmt: string,
  featured_media:
    | number
    | {
        id: number,
        src: string,
        legend: string,
        alt: string,
        width: number,
        height: number
      },

  label: {
    id: number,
    name: string,
    slug: string
  },
  guid: { rendered: string },
  modified: string,
  modified_gmt: string,
  author: number,
  categories: number[],
  comment_status: string,
  content: { rendered: string, protected: boolean, data: BlocContent[] },
  excerpt: { rendered: string, protected: boolean },
  featured_video: { rendered: string, data: BlocContent[] }, 
  featured_media: number,
  format: string,
  link: string,
  meta: Object,
  ping_status: string,
  slug: string,
  sticky: boolean,
  tags_list: Array<{
    id: number,
    name: string
  }>,
  template?: string,
  title: Object,
  type: string,
  _links: Object,
  sidebar_content: {
    data: BlocContent[]
  },
};

export type CategoryType = {
  id: number,
  name: string,
  slug: string
};

export type RawCategoryType = {
  id: number,
  count: number,
  description: string,
  link: string,
  name: string,
  slug: string,
  taxonomy: string,
  parent: number,
  meta: any[],
  _links: {
    self: Array<{
      href: string
    }>,
    collection: Array<{
      href: string
    }>,
    about: Array<{
      href: string
    }>,
    'wp:post_type': Array<{
      href: string
    }>,
    curies: Array<{
      name: string,
      href: string,
      templated: boolean
    }>
  }
};

export type CategoryArticleType = {
  id: number,
  name: string,
  slug: string,
  items: ArticleType[]
};

export type RawCategoryArticleType = {
  id: number,
  count: number,
  description: string,
  link: string,
  name: string,
  slug: string,
  taxonomy: string,
  parent: number,
  meta: any[],
  _links: {
    self: Array<{
      href: string
    }>,
    collection: Array<{
      href: string
    }>,
    about: Array<{
      href: string
    }>,
    'wp:post_type': Array<{
      href: string
    }>,
    curies: Array<{
      name: string,
      href: string,
      templated: boolean
    }>
  },
  items: ArticleType[]
};

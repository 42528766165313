// @flow

import { AGREGATOR_URL } from 'constants/api';
import { convertCompetitionFromBoApi } from 'services/Competition';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { cleanArrayFromDuplicateObjects } from 'utils/arrayUtils';

function CompetitionApi(executor: ApiExecutorType) {
  return { fetchCompetitions, fetchCompetitionMeta, fetchCompetitionData, fetchCompetitionsElements };

  function fetchCompetitions(ligue: number) {
    const date = new Date();
    date.setDate(date.getDate() - 7);

    return fetch(AGREGATOR_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `query($ligue: ID, $date: Date) {
            Competitions(Ligue: { id: $ligue }, order: "nom") {
              id

              ClasseAge {
                code
          
                Sexe {
                  code
                }
              }
          
              nom
          
              Famille {
                nom
              }
          
              dernieresRencontres(dateDebut: $date) {
                id
              }
              
              identifiant
            }
          }
          `,
        variables: { ligue: parseInt(ligue, 10), date }
      })
    })
      .then(res => res.json())
      .then(({ data, errors }) => {
        if (errors) {
          throw errors[0];
        }

        return data.Competitions.map(compet => ({
          type: compet.Famille.nom.toLowerCase(),
          division: compet.nom,
          slug: compet.identifiant,
          id: compet.id,
          date: new Date(),
          nouveaux_resultats: compet.dernieresRencontres.length
        }));
      });
  }

  function fetchCompetitionsElements(ligue: number) {
    const date = new Date();
    date.setDate(date.getDate() - 7);

    return fetch(AGREGATOR_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `query($ligue: ID, $date: Date) {
            Competitions(Ligue: { id: $ligue }, order: "nom") {
              ClasseAge {
                code
          
                Sexe {
                  code
                }
              }
          
              nom
          
              Famille {
                nom
              }
          
              dernieresRencontres(dateDebut: $date) {
                id
              }
              
              identifiant

              StructureOrganisatrice {
                id
                nom
              }
            }
          }
          `,
        variables: { ligue: parseInt(ligue, 10), date }
      })
    })
      .then(res => res.json())
      .then(({ data, errors }) => {
        if (errors) {
          throw errors[0];
        }

        return data.Competitions.map(compet => ({
          age: compet.ClasseAge.code,
          division: compet.nom,
          famille: compet.Famille.nom.toLowerCase(),
          first: { slug: '', title: '', src: '' },
          order: 0,
          recents: compet.dernieresRencontres.length,
          sexe: compet.ClasseAge.Sexe && compet.ClasseAge.Sexe.code,
          slug: compet.identifiant,
          StructureOrganisatrice: {
            id: parseInt(compet.StructureOrganisatrice.id),
            nom: compet.StructureOrganisatrice.nom
          } 
        }));
      });
  }

  function fetchCompetitionMeta(slug: string) {
    return executor
      .get(`/ffr/v1/competitions?identifiant=${slug}`)
      .then(response => convertCompetitionFromBoApi(response));
  }

  function formatRencontre(r, pouleId, pouleOrdre, equipeLocal, equipeVisiteuse) {
    const visiteur = equipeVisiteuse.Structure || equipeVisiteuse.Regroupement;
    const local = equipeLocal.Structure || equipeLocal.Regroupement;

    return {
      locaux: local.nom,
      locaux_score: r.RencontreResultatLocale && r.RencontreResultatLocale.pointsDeMarque,
      visiteurs: visiteur.nom,
      visiteurs_score: r.RencontreResultatVisiteuse && r.RencontreResultatVisiteuse.pointsDeMarque,
      id_ovale: r.id,
      rencontre_id: r.id,
      poule: pouleId,
      poule_ordre: pouleOrdre,
      etat: r.Etat.nom
    };
  }

  function formatDayRencontres(rencontres, Equipes) {
    return rencontres.reduce((acc, r) => {
      const pouleOrdre = r.ordrePoule;
      let pouleId = '0';

      const equipeLocal = Equipes.find(e => e.id == r.competitionEquipeLocaleId);
      const equipeVisiteuse = Equipes.find(e => e.id == r.competitionEquipeVisiteuseId);

      if (equipeLocal.CompetitionPoule.ordre == pouleOrdre) {
        pouleId = equipeLocal.CompetitionPoule.id;
      } else if (equipeVisiteuse.CompetitionPoule.ordre == pouleOrdre) {
        pouleId = equipeVisiteuse.CompetitionPoule.id;
      }
      const poule = acc.find(item => item.pouleOrdre == pouleOrdre);
      const value = formatRencontre(r, pouleId, pouleOrdre, equipeLocal, equipeVisiteuse);

      if (poule) {
        poule.rencontres.push(value);

        return acc;
      }

      return acc.concat({ pouleId, pouleOrdre, rencontres: [value] });
    }, []);
  }

  function fetchCompetitionData(slug: string, ligue_id: number) {
    return fetch(AGREGATOR_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `fragment rencontre on Rencontre {
            ordrePoule
            id
            Etat {
              nom
            }
            RencontreResultatLocale {
              pointsDeMarque
            }
            RencontreResultatVisiteuse {
              pointsDeMarque
            }
            competitionEquipeLocaleId
  					CompetitionEquipeLocale {
              nom
            }
            competitionEquipeVisiteuseId
  					CompetitionEquipeVisiteuse {
              nom
            }
          }
          
          fragment day on Journee {
            id
            dateDebut
            nom
            Phase {
              id
              nom
            }
            RencontresStructures: Rencontres(Structure: { structureParentId: $ligueId }) {
              ...rencontre
            }
          
            RencontresRegroupements: Rencontres(Regroupement: { structureId: $ligueId }) {
              ...rencontre
            }
          }
          
          fragment classment on Classement {
            bonusDefensif
            bonusOffensif
            essaiConcedes
            essaiMarques
            gagnes
            goalAverage
            joues
            nuls
            perdus
            pointTerrain
            pointsDeMarqueAcquis
            pointsDeMarqueAcquis
            regulationPointsTerrain
          }
          
          query($slug: String, $today: Date, $ligueId: Int) {
            Competition(identifiant: $slug) {
              nom
              identifiant
              id
              nextDay: Journees(limit: 1, order: "dateDebut", dateDebut: $today) {
                ...day
              }
    
              derniereRencontre(Structure: {structureParentId: $ligueId}) {
                Journee {
                  ...day
                }
              }
    
              derniereRencontreRegroupement: derniereRencontre(Regroupement: {structureId: $ligueId}) {
                Journee {
                  ...day
                }
              }

              CurrentPhase {
                id
                nom

                ClassementsInterPoule {
                  Classements {
                    positionInterPoule

                    Classement {
                      ...classment
                    }

                    Equipe {
                      id
                    }
                  }
                }

                ClassementsIntraPoule(ligue: $ligueId) {
                  ordrePoule
                  pouleId

                  Classements {
                    position

                    Classement {
                      ...classment
                    }

                    Equipe {
                      id
                    }
                  }
                }
              }
              
              Equipes {
                id
      
                DerniersResultats(limit: 5) {
                  id
                  dateEffective
                  competitionEquipeLocaleId
                  competitionEquipeVisiteuseId
                  RencontreResultatLocale {
                    pointsDeMarque
                  }
                  RencontreResultatVisiteuse {
                    pointsDeMarque
                  }
                }

                CompetitionPoule {
                  id
                  ordre
                }

                Structure {
                  id
                  identifiant
                  nom
                  embleme
                  StructureParent {
                    id
                  }
                }
                Regroupement {
                  id
                  identifiant
                  nom
                  embleme
                  Structure {
                    id
                    nom
                  }
                }
              }
            }
          }          
          `,
        variables: { slug, today: new Date(), ligueId: parseInt(ligue_id, 10) }
      })
    })
      .then(res => res.json())
      .then(({ data, errors }) => {
        if (errors) {
          throw errors[0];
        }

        const {
          nom,
          identifiant,
          derniereRencontre,
          derniereRencontreRegroupement,
          nextDay: [nextDay],
          Equipes,
          CurrentPhase
        } = data.Competition;

        const prevDayReg = derniereRencontreRegroupement && derniereRencontreRegroupement.Journee;
        const prevDayStruct = derniereRencontre && derniereRencontre.Journee;
        const prevDay =
          (prevDayStruct && prevDayStruct.dateDebut) < (prevDayReg && prevDayReg.dateDebut)
            ? prevDayReg
            : prevDayStruct;
        // on supprime les doublons de rencontres (celles qui pourraient être dans RencontresStructures ET RencontresRegroupements)
        // on supprime les matchs contre un club exempt puisque cela signigie qu'il n'y a pas eu de match    
        const prevDayRencontres =
          prevDay &&
          cleanArrayFromDuplicateObjects(
            prevDay.RencontresStructures.concat(prevDay.RencontresRegroupements).filter(
              r => r.RencontreResultatLocale && r.RencontreResultatVisiteuse
            )
          ).filter(rencontre => !rencontre.CompetitionEquipeLocale.nom.includes('EXEMPT') && !rencontre.CompetitionEquipeVisiteuse.nom.includes('EXEMPT'));

        let classement = [];
        if (CurrentPhase && CurrentPhase.ClassementsIntraPoule && CurrentPhase.ClassementsIntraPoule.length) {
          classement = CurrentPhase.ClassementsIntraPoule.map(item => {
            const pouleClassment = item.Classements.map(c => {
              const equipe = Equipes.find(e => e.id == c.Equipe.id);
              const team = equipe.Structure || equipe.Regroupement;
              const parent = team.StructureParent || team.Structure;

              return {
                refCompetitionClassementEtatId: 1,
                equipe: {
                  id: team.id,
                  nom: team.nom,
                  url: team.embleme,
                  url_type: parent && parent.id == ligue_id ? 'interne' : 'externe'
                },
                position: c.position,
                ...c.Classement,
                last_matchs: equipe.DerniersResultats.map(r => ({
                  id: r.id,
                  date: new Date(r.dateEffective),
                  win:
                    r.competitionEquipeLocaleId == c.Equipe.id
                      ? r.RencontreResultatLocale.pointsDeMarque - r.RencontreResultatVisiteuse.pointsDeMarque
                      : r.RencontreResultatVisiteuse.pointsDeMarque - r.RencontreResultatLocale.pointsDeMarque,
                  name: ''
                }))
              };
            }).sort((a, b) => a.position - b.position);

            return {
              pouleId: item.pouleId,
              classement: pouleClassment,
              premiers: pouleClassment.slice(0, 2),
              pouleOrdre: item.ordrePoule
            };
          });
        } else if (CurrentPhase && CurrentPhase.ClassementsInterPoule) {
          const pouleClassement = CurrentPhase.ClassementsInterPoule.reduce((acc, { Classements }) => {
            return acc.concat(
              Classements.map(c => {
                const equipe = Equipes.find(e => e.id == c.Equipe.id);
                const team = equipe.Structure || equipe.Regroupement;
                const parent = team.StructureParent || team.Structure;
                const isInterne = parent && parent.id == ligue_id;

                return {
                  refCompetitionClassementEtatId: 1,
                  equipe: {
                    id: team.id,
                    nom: team.nom,
                    url: isInterne
                      ? `/clubs/${team.identifiant}`
                      : `https://competitions.ffr.fr/clubs/${team.identifiant}`,
                    url_type: isInterne ? 'interne' : 'externe'
                  },
                  position: c.positionInterPoule,
                  ...c.Classement,
                  last_matchs: equipe.DerniersResultats.map(r => ({
                    id: r.id,
                    date: new Date(r.dateEffective),
                    win:
                      r.competitionEquipeLocaleId == equipe.id
                        ? r.RencontreResultatLocale.pointsDeMarque - r.RencontreResultatVisiteuse.pointsDeMarque
                        : r.RencontreResultatVisiteuse.pointsDeMarque - r.RencontreResultatLocale.pointsDeMarque,
                    name: ''
                  }))
                };
              })
            );
          }, []).sort((a, b) => a.position - b.position);

          classement = [
            {
              pouleId: '0',
              classement: pouleClassement,
              premiers: pouleClassement.slice(0, 2),
              pouleOrdre: '0'
            }
          ];
        }

        const concatNextDay = nextDay ? nextDay.RencontresStructures.concat(nextDay.RencontresRegroupements) : [];
        // on supprime les doublons de rencontres (celles qui pourraient être dans RencontresStructures ET RencontresRegroupements)
        // on supprime les matchs contre un club exempt puisque cela signigie qu'il n'y a pas eu de match    
        const filteredNextDay = cleanArrayFromDuplicateObjects(concatNextDay)
          .filter(rencontre => !rencontre.CompetitionEquipeLocale.nom.includes('EXEMPT') && !rencontre.CompetitionEquipeVisiteuse.nom.includes('EXEMPT'));

        return {
          division: nom,
          nextDay: nextDay
            ? {
                journee_id: nextDay.id,
                nombre_rencontres: filteredNextDay.length,
                journee_date: new Date(nextDay.dateDebut),
                journee_nom: nextDay.nom,
                phase_id: nextDay.Phase.id,
                phase_nom: nextDay.Phase.nom,
                poules: formatDayRencontres(filteredNextDay, Equipes)
              }
            : { poules: [] },
          participants: Equipes.map(e => {
            const team = e.Structure || e.Regroupement;
            const parent = team.StructureParent || team.Structure;
            const isInterne = parent && parent.id == ligue_id;

            return {
              id: team.id,
              nom: team.nom,
              slug: team.identifiant,
              url: isInterne ? `/clubs/${team.identifiant}` : `https://competitions.ffr.fr/clubs/${team.identifiant}`,
              url_type: isInterne ? 'interne' : 'externe',
              poule: e.CompetitionPoule.id,
              poule_ordre: e.CompetitionPoule.ordre,
              ovale_id: team.id,
              embleme: team.embleme
            };
          }),
          prevDay: prevDay
            ? {
                journee_id: prevDay.id,
                nombre_rencontres: prevDayRencontres.length,
                journee_date: new Date(prevDay.dateDebut),
                journee_nom: prevDay.nom,
                phase_id: prevDay.Phase.id,
                phase_nom: prevDay.Phase.nom,
                // on supprime les doublons de rencontres (celles qui pourraient être dans RencontresStructures ET RencontresRegroupements)
                poules: formatDayRencontres(prevDayRencontres, Equipes)
              }
            : { poules: [] },
          ranking: { poules: classement },
          slug: identifiant
        };
      });
  }
}

export default CompetitionApi;

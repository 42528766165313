// @flow
import React, { PureComponent } from 'react';
import type { NewsletterSubscriptionType, NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';
import type { Status } from 'types/Status';
import { STATUS_DEFAULT } from 'constants/statusConstants';
import SelectInput from 'components/fragments/input/SelectInput';
import TextInput from 'components/fragments/input/TextInput';
import CheckboxInput from 'components/fragments/input/CheckboxInput';
import type { DisplayModalFunctionType } from 'types/Modal';
import { validateEmail } from 'utils/validatorUtils';

export type StateProps = {
  options: NewsletterSubscriptionType,
  status: Status,
  newsletterSetting: { type: false | 'campaign_monitor' | 'lien', lien?: string, key?: string },
  structureName: string
};

export type DispatchProps = {
  fetchOptions: (campaignMonitorKey: string) => void,
  subscribeNewsletter: (subscription: NewsletterSubscriptionEntryType, campaignMonitorKey: string) => void,
  displayModal: DisplayModalFunctionType
};

type Props = {} & StateProps & DispatchProps;

type State = {
  profil: null | string,
  email: null | string,
  consentToTrack: boolean,
  consentOpt1: boolean,
  consentOpt2: boolean,
  emailValide: boolean
};

class NewsletterSubscriber extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    profil: null,
    email: null,
    consentToTrack: false,
    consentOpt1: false,
    consentOpt2: false,
    emailValide: false
  };

  componentDidMount() {
    const { newsletterSetting, fetchOptions } = this.props;
    if (
      newsletterSetting &&
      newsletterSetting.type &&
      newsletterSetting.type === 'campaign_monitor' &&
      newsletterSetting.key
    ) {
      fetchOptions(newsletterSetting.key || '');
    }
  }

  componentDidUpdate() {
    const { newsletterSetting, status, fetchOptions } = this.props;
    if (newsletterSetting.type === 'campaign_monitor' && status === STATUS_DEFAULT) {
      fetchOptions(newsletterSetting.key || '');
    }
  }

  setProfil = (profil: string) => {
    this.setState({ profil });
  };

  setEmail = (email: string) => {
    if (validateEmail(email)) {
      this.setState({
        email,
        emailValide: true
      });
    } else {
      this.setState({ emailValide: false });
    }
  };

  setConsent = (consentToTrack: boolean) => {
    this.setState({ consentToTrack });
  };

  setConsentOpt1 = (consentOpt1: boolean) => {
    this.setState({ consentOpt1 });
  };

  setConsentOpt2 = (consentOpt2: boolean) => {
    this.setState({ consentOpt2 });
  };

  handleSubmitClicked = (e: MouseEvent) => {
    e.preventDefault();

    const { structureName } = this.props;

    if (this.state.profil === null || this.state.email === null) {
      this.setState({
        profil: this.state.profil === null ? '' : this.state.profil,
        email: this.state.email === null ? '' : this.state.email
      });
    } else if (!this.state.consentToTrack) {
      this.props.displayModal('NEWSLETTER_MISSING_OPT', { structureName });
    } else if (this.state.profil !== '' && validateEmail(this.state.email)) {
      this.props.subscribeNewsletter(
        {
          ConsentToTrack: this.state.consentToTrack ? 'yes' : 'no',
          CustomFields: [
            {
              Key: 'Jesuis',
              Value: this.state.profil
            },
            {
              Key: 'Optin1',
              Value: this.state.consentOpt1 ? 'yes' : 'no'
            },
            {
              Key: 'Optin2',
              Value: this.state.consentOpt2 ? 'yes' : 'no'
            }
          ],
          EmailAddress: this.state.email,
          Name: '',
          action: 'subscribe'
        },
        this.props.newsletterSetting.key || ''
      );
    }
  };

  renderLinkButton = () => {
    const { structureName } = this.props;
    return (
      <div className="col col-lg-9">
        <h5 className="mb-1">Newsletter</h5>
        <p className="mb-2">Recevez par email les nouveautés de la ligue {structureName} en fonction de votre profil</p>
        <a
          title={`S'inscrire à la newsletter de la ligue ${structureName}`}
          className="btn btn--primary"
          href={this.props.newsletterSetting.lien || '#'}
        >
          {"S'inscrire"}
        </a>
      </div>
    );
  };

  render() {
    if (
      this.props.newsletterSetting.type === false ||
      (this.props.newsletterSetting.type === 'campaign_monitor' && this.props.status === STATUS_DEFAULT)
    )
      return null;
    if (this.props.newsletterSetting.type === 'lien') return this.renderLinkButton();
    else {
      if (this.props.options.options.length === 0) {
        return null;
      }
      const { structureName } = this.props;
      const { emailValide } = this.state;
      return (
        <div className="col col-lg-9">
          <h5 className="mb-1">Newsletter</h5>
          <p className="mb-2">Recevez par email les nouveautés de la {structureName} en fonction de votre profil</p>
          <form action="#">
            <SelectInput
              isDark
              placeholder="Je suis..."
              options={this.props.options.options.map(option => ({ label: option, value: option }))}
              onChange={this.setProfil}
              hasError={this.state.profil === ''}
            />
            <TextInput
              id="email"
              isDark
              placeholder="Adresse mail*"
              hasError={this.state.email !== null && !validateEmail(this.state.email)}
              onChange={this.setEmail}
            />
            <div>
              <CheckboxInput
                isDark
                id="consent"
                label={`J'accepte les emails de la ${structureName} de rugby à propos des nouveautés et promotions de la Ligue.
                Je peux me désinscrire à tout moment en utilisant le lien pour me désabonner au pied de la newsletter.`}
                onChange={this.setConsent}
              />
              <CheckboxInput
                isDark
                id="consentOpt1"
                label={`J’autorise la FFR à utiliser mes données personnelles (e-mail, adresse postale) à des fins
                promotionnelles ou commerciales pour ses propres activités (billetterie, produits dérivés).`}
                onChange={this.setConsentOpt1}
              />
              <CheckboxInput
                isDark
                id="consentOpt2"
                label={`J’autorise la FFR à transmettre certaines de mes données personnelles (nom, prénom, e-mail, adresse postale)
                à des tiers, et notamment ses partenaires et fournisseurs officiels, à des fins commerciales, associatives ou humanitaires.`}
                onChange={this.setConsentOpt2}
              />
            </div>
            <button
              className="btn btn--primary"
              type="submit"
              onClick={this.handleSubmitClicked}
              disabled={!emailValide && true}
            >
              <i className="icon icon-valid is-inline" />
              Inscription
            </button>
          </form>
        </div>
      );
    }
  }
}

export default NewsletterSubscriber;

/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
  id: string,
  options: Array<{ label: string, value: any }>,
  prelabel?: string,
  maj?: boolean,
  title?: boolean,
  radio?: boolean,
  navBar?: boolean,
  noBorder?: boolean,
  big?: boolean,
  marge?: boolean,
  packed?: boolean,
  labelIcon?: string,
  isShadow?: boolean,
  isActive?: boolean,
  isPreLabelThin?: boolean,
  inputChanged?: (value: string) => void
};

type State = {
  label: string,
  isOpen: boolean
};

class DropdownRadioInput extends PureComponent<Props, State> {
  props: Props;
  wrapperRef: any;

  static defaultProps = {
    id: 'dropdownradioinput123',
    options: [
      {
        value: 'option1',
        label: 'option1'
      }
    ],
    isActive: true,
    isPreLabelThin: true
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      label: props.options[0] ? props.options[0]['label'] : '',
      isOpen: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { isOpen } = this.state;
    const { isActive } = this.props;
    const classNames = dynamicClassName('dropdown');
    isOpen && classNames.add('is-open');
    this.props.marge && classNames.add('mb-3');
    this.props.radio && classNames.add('dropdown--radio');
    this.props.navBar && classNames.add('dropdown--navbar');
    this.props.noBorder && classNames.add('dropdown--noBorder');
    this.props.big && classNames.add('dropdown--big');
    this.props.packed && classNames.add('mt-2 mt-md-0');

    const classNamesDropdownHead = dynamicClassName('dropdown__head');
    this.props.maj && classNamesDropdownHead.add('ft-up');
    this.props.title && classNamesDropdownHead.add('ft-h6');

    const classNamesLabelIcon = dynamicClassName('icon is-inline');
    this.props.labelIcon && classNamesLabelIcon.add(this.props.labelIcon);

    const classNamesDropdownList = dynamicClassName('dropdown__list');
    this.props.isShadow && classNames.add('shadow-3');

    const classNamesPreLabel = dynamicClassName('dropdown__prelabel');
    this.props.isPreLabelThin && classNamesPreLabel.add('ft-500');

    if (isActive) {
      return (
        <div className={classNames.build()} ref={this.setWrapperRef}>
          <span
            role={'button'}
            tabIndex={0}
            className={classNamesDropdownHead.build()}
            onClick={this.handleClickDropDown}
          >
            {this.props.labelIcon && <i className={classNamesLabelIcon.build()} />}
            {this.props.prelabel && <span className={classNamesPreLabel.build()}>{this.props.prelabel}</span>}
            <span className="dropdown__label">{this.state.label}</span>
          </span>
          <div className={classNamesDropdownList.build()}>
            <span
              role={'button'}
              tabIndex={0}
              className={classNamesDropdownHead.build()}
              onClick={this.handleClickDropDown}
            >
              {this.props.labelIcon && <i className={classNamesLabelIcon.build()} />}
              {this.props.prelabel && <span className="dropdown__prelabel ft-500">{this.props.prelabel}</span>}
              <span className="dropdown__label">{this.state.label}</span>
            </span>
            <div className="dropdown__wrapper">{this.renderOptions(this.props.options)}</div>
          </div>
        </div>
      );
    }

    return (
      <div className={classNames.build()} ref={this.setWrapperRef} style={{ opacity: 0.2 }}>
        <span className={classNamesDropdownHead.build()} style={{ cursor: 'initial' }}>
          {this.props.labelIcon && <i className={classNamesLabelIcon.build()} />}
          {this.props.prelabel && <span className="dropdown__prelabel ft-500">{this.props.prelabel}</span>}
        </span>
      </div>
    );
  }

  renderOptions = (options: any) => {
    return options.map((option, index) => (
      <div className="radio" key={index}>
        <input type="radio" id={this.props.id + index} defaultChecked={this.state.label === option.label} />
        <label htmlFor={this.props.id + index} onClick={this.handleChecked.bind(this, option.value, option.label)}>
          {option.label}
        </label>
      </div>
    ));
  };

  handleClickDropDown = (e: MouseEvent) => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleChecked = (value: string, label: string) => {
    if (this.props.inputChanged) this.props.inputChanged(value);
    this.setState({
      label,
      isOpen: false
    });
  };

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };
}

export default DropdownRadioInput;

// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_CLUBS_START,
  FETCH_CLUBS_SUCCESS,
  FETCH_CLUBS_FAILURE,
  FETCH_PRACTICES_FAILURE,
  FETCH_PRACTICES_SUCCESS,
  FETCH_PRACTICES_START,
  FETCH_COMPETITIONS_FILTER_ERROR,
  FETCH_COMPETITIONS_FILTER_START,
  FETCH_COMPETITIONS_FILTER_SUCCESS
} from 'actions/clubsActions';

import type { ClubPreviewType, Practice } from 'types/Club';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  clubs: Array<ClubPreviewType>,
  status: Status,

  practices: Array<Practice>,
  practicesStatus: Status,

  competitions: { Competitions: Array<any>, Ligue: { nom: string } },
};

const initialState: State = {
  clubs: [],
  status: STATUS_DEFAULT,

  practices: [],
  practicesStatus: STATUS_DEFAULT,

  competitions: { Competitions: [], Ligue: { nom: '' } },
};

export default function (state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_CLUBS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_CLUBS_SUCCESS:
      return {
        ...state,
        clubs: action.payload.clubs,
        status: STATUS_SUCCESS
      };
    case FETCH_CLUBS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };

    case FETCH_PRACTICES_FAILURE:
      return { ...state, practicesStatus: STATUS_FAILURE };
    case FETCH_PRACTICES_START:
      return { ...state, practicesStatus: STATUS_LOADING };
    case FETCH_PRACTICES_SUCCESS:
      return { ...state, practicesStatus: STATUS_SUCCESS, practices: action.payload.practices };


    case FETCH_COMPETITIONS_FILTER_ERROR:
    case FETCH_COMPETITIONS_FILTER_START:
      return state;
    case FETCH_COMPETITIONS_FILTER_SUCCESS:
      return { ...state, competitions: { ...action.payload } };


    default:
      return state;
  }
}

// @flow
import type { RawPageListType, PageListType } from 'types/PageList';
import type { RawPageNodeType, PageNodeType } from 'types/PageNode';
import type { RawPageDetailType, PageDetailType } from 'types/PageDetail';
import { nullPageList } from 'types/PageList';
import { nullPageNode } from 'types/PageNode';
import { nullPageDetail } from 'types/PageDetail';
import { mergeIntoDefault, convertToValidDateFormat } from 'utils/objectUtils';
import { convertEncodedHTML } from 'utils/convertUtils';
import { store } from './../store';

export function convertPageListFromApi(rawPageList: Array<RawPageListType>): PageListType {
  if (!rawPageList) {
    return nullPageList;
  }

  let liste_enfantsMerged = nullPageList.liste_enfants;
  let labelMerged = nullPageList.label;

  if (rawPageList.length === 0) {
    return nullPageList;
  }

  const {
    title,
    parent,
    content,
    sidebar_content,
    slug,
    label,
    featured_media,
    liste_enfants,
    niveau,
    meta_title,
    meta_description,
    template,
    excerpt,
  } = rawPageList[0];

  if (liste_enfants) {
    liste_enfantsMerged = liste_enfants.map(liste_enfant => {
      return mergeIntoDefault(nullPageList.liste_enfants[0], liste_enfant);
    });
  }

  if (label) {
    labelMerged = label;
  }

  return mergeIntoDefault(nullPageList, {
    title: title.raw,
    excerpt: excerpt.raw,
    description: content.data,
    sidebar_content: sidebar_content ? sidebar_content.data : [],
    parent,
    slug,
    label: {
      id: labelMerged.id,
      name: convertEncodedHTML(labelMerged.name),
      slug: labelMerged.slug
    },
    featured_media,
    liste_enfants: liste_enfantsMerged,
    niveau,
    meta_title,
    meta_description,
    template,
  });
}

export function convertPageNodeFromApi(rawPageNode: Array<RawPageNodeType>): PageNodeType {
  if (!rawPageNode) {
    return nullPageNode;
  }
  let liste_enfantsMerged;

  if (rawPageNode.length === 0) {
    return nullPageNode;
  }

  const {
    id,
    title,
    title_parent,
    parent,
    excerpt,
    slug_parent,
    slug,
    featured_media,
    liste_enfants,
    content,
    sidebar_content,
    niveau,
    meta_title,
    meta_description,
  } = rawPageNode[0];

  if (liste_enfants) {
    liste_enfantsMerged = liste_enfants.map(liste_enfant => {
      return mergeIntoDefault(nullPageNode.liste_enfants[0], liste_enfant);
    });
  } else {
    liste_enfantsMerged = nullPageNode.liste_enfants;
  }

  return mergeIntoDefault(nullPageNode, {
    id: id,
    title: title.raw,
    titleParent: convertEncodedHTML(title_parent),
    parent,
    description: excerpt.raw,
    content: content.data,
    sidebar_content: sidebar_content ? sidebar_content.data : [],
    slug_parent,
    slug,
    featured_media,
    liste_enfants: liste_enfantsMerged,
    niveau,
    meta_title,
    meta_description,
  });
}

export function convertPageDetailFromApi(rawPageDetails: Array<RawPageDetailType>): PageDetailType {
  if (!rawPageDetails) {
    return nullPageDetail;
  }
  const { pageNodeState } = store.getState();

  const rawPageDetailFiltered = rawPageDetails.filter(
    rawPageDetail => rawPageDetail.parent === pageNodeState.pageNode.id
  );

  if (rawPageDetailFiltered[0]) {
    const {
      title,
      content,
      slug,
      featured_media,
      niveau,
      documents: rawDocuments,
      annuaires,
      personnes,
      sidebar_content,
      personnes_departement,
      meta_title,
      meta_description,
      template,
    } = rawPageDetailFiltered[0];

    let membersGroups;
    let annuairesGroups;

    const documents = (rawDocuments || []).map(doc => {
      if (!doc.date) {
        return doc;
      }

      return {
        ...doc,
        date: convertToValidDateFormat(doc.date),
      };
    });

    if (personnes) {
      membersGroups = personnes.reduce((acc, obj) => {
        const cle = obj.title[0];
        const tmp = acc.filter(tab => tab.name === cle);
        if (tmp.length > 0) {
          tmp[0].child.push(obj);
          return acc;
        } else {
          return [
            ...acc,
            {
              name: cle,
              child: [
                obj
              ],
            }
          ];
        }
      }, [])
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }
    
    if (annuaires) {
      annuairesGroups = annuaires.map(annuaire => {
        return {
          title: annuaire.title,
          personnes: annuaire.personnes.reduce((acc, obj) => {
            const cle = obj.title[0];
            const tmp = acc.filter(tab => tab.name === cle);
            if (tmp.length > 0) {
              tmp[0].child.push(obj);
              return acc;
            } else {
              return [
                ...acc,
                {
                  name: cle,
                  child: [
                    obj
                  ],
                }
              ];
            }
          }, [])
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          })
        };
      });
    }


    return mergeIntoDefault(nullPageDetail, {
      content: content.data,
      title: title ? title.raw : '',
      slug,
      featured_media,
      niveau,
      annuaires: annuairesGroups,
      documents,
      membersGroups,
      sidebar_content: sidebar_content.data,
      departmentsGroups: personnes_departement,
      meta_title,
      meta_description,
      template,
    });
  }
  return nullPageDetail;
}

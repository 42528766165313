// @flow
import type { CardType } from 'types/PageList';
import type { BlocContent } from 'types/NewsletterBloc';
import type { SourceSetType } from 'types/Article';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type PageNodeType = SEOMetaType & {
  id: number,
  title: string,
  titleParent: string,
  description: string,
  sidebar_content: BlocContent[],
  content: BlocContent[],
  slug_parent: string,
  slug: string,
  parent: number,
  featured_media: {
    src: string,
    legend: string,
    size: SourceSetType
  },
  liste_enfants: CardType[],
  niveau: number,
  template: '',
};

export type RawPageNodeType = SEOMetaType & {
  id: number,
  title: {
    raw: string
  },
  title_parent: string,
  parent: number,
  excerpt: {
    raw: string
  },
  sidebar_content: {
    data: BlocContent[]
  },
  content: {
    data: BlocContent[]
  },
  slug: string,
  slug_parent: string,
  featured_media: {
    src: string,
    legend: string,
    size: SourceSetType
  },
  liste_enfants: CardType[],
  niveau: number
};

export const nullPageNode: PageNodeType = {
  ...nullSeoMeta,
  id: 0,
  title: '',
  titleParent: '',
  description: '',
  content: [],
  sidebar_content: [],
  parent: 0,
  slug_parent: '',
  slug: '',
  featured_media: {
    src: '',
    legend: '',
    size: {}
  },
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: ''
    }
  ],
  niveau: 0,
  template: '',
};

export const loadingPageNode: PageNodeType = {
  ...nullSeoMeta,
  id: 0,
  title: 'Chargement',
  titleParent: 'Chargement',
  description: '',
  content: [],
  sidebar_content: [],
  parent: 0,
  slug_parent: '',
  slug: '',
  featured_media: {
    src: '',
    legend: '',
    size: {}
  },
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: 'Chargement',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: ''
    }
  ],
  niveau: 2,
  template: '',
};

// @flow

import {
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS,
  STATUS_DEFAULT,
  STATUS_UNKNOWN
} from 'constants/statusConstants';
import {
  FETCH_JOB_DETAIL_START,
  FETCH_JOB_DETAIL_SUCCESS,
  FETCH_JOB_DETAIL_FAILURE,
  FETCH_UNKNOWN
} from 'actions/jobActions';

import type { ActionType } from 'types/Actions';
import type { Status } from 'types/Status';
import type { BlocContent } from 'types/NewsletterBloc';
import type { JobDetailType } from 'types/JobDetail';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';
import { nullJobDetail } from 'types/JobDetail';

export type State = SEOMetaType & {
  details: JobDetailType,
  status: Status,
  sidebar_content: Array<BlocContent>,
};

const initialState: State = {
  ...nullSeoMeta,
  details: {
    ...nullJobDetail,
  },
  status: STATUS_DEFAULT,
  sidebar_content: []
};

export default function jobDetailReducer(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_JOB_DETAIL_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_JOB_DETAIL_SUCCESS:
      return {
        ...state,
        details: action.payload.details,
        status: STATUS_SUCCESS
      };
    case FETCH_JOB_DETAIL_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN,
      };
    default:
      return state;
  }
}

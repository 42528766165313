// @flow

import type { SettingsType } from 'types/Settings';

export const APP_SET_SHOW_COOKIE_INFO = 'APP_SET_SHOW_COOKIE_INFO';
export const APP_SET_SHOW_ALERT_INFO = 'APP_SET_SHOW_ALERT_INFO';
export const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

export type APP_SET_SHOW_COOKIE_INFO_ACTION = {
  type: 'APP_SET_SHOW_COOKIE_INFO',
  payload: {
    showCookieInfo: boolean,
    acceptCookie: boolean,
  }
};

export type APP_SET_SHOW_ALERT_INFO_ACTION = {
  type: 'APP_SET_SHOW_ALERT_INFO',
  payload: {
    showAlertInfo: string
  }
};

export type FETCH_SETTINGS_START_ACTION = {
  type: 'FETCH_SETTINGS_START'
};

export type FETCH_SETTINGS_FAILURE_ACTION = {
  type: 'FETCH_SETTINGS_FAILURE'
};

export type FETCH_SETTINGS_SUCCESS_ACTION = {
  type: 'FETCH_SETTINGS_SUCCESS',
  payload: {
    settings: SettingsType
  }
};

export type Action = APP_SET_SHOW_COOKIE_INFO_ACTION
  | APP_SET_SHOW_ALERT_INFO_ACTION
  | FETCH_SETTINGS_START_ACTION
  | FETCH_SETTINGS_FAILURE_ACTION
  | FETCH_SETTINGS_SUCCESS_ACTION;

export function setShowCookieInfo(showCookieInfo: boolean, acceptCookie: boolean): APP_SET_SHOW_COOKIE_INFO_ACTION {
  return {
    type: APP_SET_SHOW_COOKIE_INFO,
    payload: { showCookieInfo, acceptCookie }
  };
}

export function setShowAlertInfo(showAlertInfo: string): APP_SET_SHOW_ALERT_INFO_ACTION {
  return {
    type: APP_SET_SHOW_ALERT_INFO,
    payload: { showAlertInfo }
  };
}

export function fetchSettings(): FETCH_SETTINGS_START_ACTION {
  return {
    type: FETCH_SETTINGS_START
  };
}

export function settingsFetched(
  settings: SettingsType
): FETCH_SETTINGS_SUCCESS_ACTION {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    payload: { settings }
  };
}

export function settingsFetchError(): FETCH_SETTINGS_FAILURE_ACTION {
  return {
    type: FETCH_SETTINGS_FAILURE
  };
}

// @flow
import HeaderFindClub from 'components/header/HeaderFindClub';
import { connect } from 'react-redux';

import type { StateType, DispatchType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/header/HeaderFindClub';
import { setCompetitions, setPractices, setDistance } from 'actions/filterOptionsActions';
import { fetchCompetitions } from 'actions/clubsActions';

export default connect(
  (state: StateType): StateProps => ({
    competitions: state.clubsState.competitions,
    selectedCompetitions: state.filterOptionsState.competitions,
    selectedPractices: state.filterOptionsState.practices,
    practices: state.clubsState.practices.map((p) => p.nom),
    activateDistance: state.filterOptionsState.activateDistance,
    ligue: state.settingsState.ligue_id,
    acceptCookie: state.appState.acceptCookie,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    getCompetitions: (ligue: number) => dispatch(fetchCompetitions(ligue)),
    setCompetitions: (competition: Array<string>) => dispatch(setCompetitions(competition)),
    setPractices: (practice: Array<string>) => dispatch(setPractices(practice)),
    setDistance: (distance: string) => dispatch(setDistance(distance))
  })
)(HeaderFindClub);

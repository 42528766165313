// npm imports
import * as Keycloak from "keycloak-js";

// local imports
import {
  KEYCLOAK_CONNECT,
  keycloakConnected,
  keycloakConnectError,
} from 'actions/userActions';
import { getInitSsoOptions } from 'constants/sso';

let authenticated = false;
let keycloakData = null;
let keycloak = null;

// middleware
export default (store) => (next) => (action) => {
  switch (action.type) {
    case KEYCLOAK_CONNECT: 
      //* check authetification
      const initSsoOptions = getInitSsoOptions();
      keycloak = Keycloak(initSsoOptions);
      let options = {
        onLoad: 'check-sso',
        // silentCheckSsoRedirectUri: ''
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
      };
      // if (window.location.hostname.match('ffr.fr')) {
      // options.silentCheckSsoRedirectUri = window.location.origin + '/silent-check-sso.html';
      // }

      // ajout de async pour créer une fonction asynchrone --> on peut donc soit await keycloakData = keycloak.loadUserProfile() soit tel que là
      keycloak.init(options).then(async authenticated => {
        if (authenticated) {  
          authenticated = true;

					keycloak.onTokenExpired = () => {
						// first param = minValidity in seconds
						keycloak.updateToken(50).then((refreshed) => {
								// if (refreshed){
								// 	// eslint-disable-next-line
								// 		console.log('refreshed ');
								// } else {
								// 	// eslint-disable-next-line
								// 		console.log('not refreshed ');
								// }

								store.dispatch(keycloakConnected({ keycloak, authenticated }));
						}).error(() => {
								// console.error('Failed to refresh token ');
						});
					};

          store.dispatch(keycloakConnected({ keycloak, authenticated }));
        } else {
          authenticated = false;
          if (window.location.pathname.match('tableau-de-bord')) {
            if (keycloak && keycloak.createLoginUrl) {
              window.location.href = keycloak.createLoginUrl();
            } else {
              window.location.href = '/';
            }
          }

          store.dispatch(keycloakConnected({ keycloak, authenticated }));
        }
      }).catch(error => {
        // console.error(error);
        store.dispatch(keycloakConnectError(error));
      });

      next(action);
      break;
    default:
      next(action);
  };
};
// @flow

export type EventType = {
  startDate: Date | string,
  endDate: Date | string,
  startDateDetail: Date,
  endDateDetail: Date,
  title: string,
  label: string,
  slug: string,
  image: string,
  description: string,
  venue: string,
  adress: string,
  zip: string,
  city: string,
  organizer: string,
  email: string,
  phone: string,
  lat: number,
  long: number,
  all_day: boolean,
  sidebar?: boolean,
  import_type?: string,
  redirection?: string,
  categories: Array<any>
};

export type EventPreviewType = {
  slug: string,
  startDate: Date | string,
  endDate: Date | string,
  title: string,
  label: string,
  venue: string,
  adress: string,
  zip: string,
  city: string,
  import_type?: string,
  redirection?: string,
  categories: Array<any>
};

export type RawEventType = {
  id: number,
  global_id: string,
  global_id_lineage: Array<string>,
  author: string,
  status: string,
  date: string,
  date_utc: string,
  modified: string,
  modified_utc: string,
  url: string,
  rest_url: string,
  title: {
    rendered: string,
    raw: string,
    data: Array<{
      type: string,
      text: string
    }>
  },
  description: {
    rendered: string,
    raw: string,
    data: Array<{
      tagName: string,
      attributes: [],
      children: Array<{
        type: string,
        text: string
      }>
    }>
  },
  excerpt: string,
  slug: string,
  image: {
    url: string,
    id: string,
    extension: string,
    width: number,
    height: number,
    sizes: {
      thumbnail: {
        width: number,
        height: number,
        'mime-type': string,
        url: string
      },
      medium: {
        width: number,
        height: number,
        'mime-type': string,
        url: string
      }
    }
  },
  all_day: boolean,
  start_date: string,
  start_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  end_date: string,
  end_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  utc_start_date: string,
  utc_start_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  utc_end_date: string,
  utc_end_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  timezone: string,
  timezone_abbr: string,
  cost: string,
  cost_details: {
    currency_symbol: string,
    currency_position: string,
    values: Array<any>
  },
  website: string,
  show_map: boolean,
  show_map_link: boolean,
  hide_from_listings: boolean,
  sticky: boolean,
  featured: boolean,
  categories: Array<any>,
  tags: [
    {
      name: string,
      slug: string,
      term_group: number,
      term_taxonomy_id: number,
      taxonomy: string,
      description: string,
      parent: number,
      count: number,
      filter: string,
      id: number,
      urls: {
        self: string,
        collection: string
      }
    }
  ],
  venue: {
    id: number,
    author: string,
    status: string,
    date: string,
    date_utc: string,
    modified: string,
    modified_utc: string,
    url: string,
    venue: string,
    slug: string,
    address: string,
    city: string,
    country: string,
    zip: string,
    show_map: boolean,
    show_map_link: boolean,
    global_id: string,
    global_id_lineage: Array<string>,
    geo_lat: number,
    geo_lng: number
  },
  organizer: [
    {
      id: number,
      author: string,
      status: string,
      date: string,
      date_utc: string,
      modified: string,
      modified_utc: string,
      url: string,
      organizer: string,
      slug: string,
      website: string,
      global_id: string,
      global_id_lineage: Array<string>
    }
  ],
  import_type?: string,
  redirection?: string
};

export type EventDetailDateType = {
  year: string,
  month: string,
  day: string,
  hour: string,
  minutes: string,
  seconds: string
};

export const nullEvent: EventType = {
  startDate: new Date(),
  endDate: new Date(),
  startDateDetail: new Date(),
  endDateDetail: new Date(),
  title: '',
  label: '',
  slug: '',
  image: '',
  description: '',
  venue: '',
  adress: '',
  zip: '',
  city: '',
  organizer: '',
  email: '',
  phone: '',
  lat: 0,
  long: 0,
  all_day: false,
  import_type: '',
  redirection: '',
  categories: []
};

export const nullEventPreview: EventPreviewType = {
  startDate: new Date(),
  endDate: new Date(),
  startDateDetail: new Date(),
  endDateDetail: new Date(),
  title: '',
  label: 'Ligue',
  venue: '',
  adress: '',
  zip: '',
  city: '',
  slug: '',
  import_type: '',
  redirection: '',
  categories: []
};

// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertArticleFromApi } from 'services/Article';
import { convertEventPreviewFromApi } from 'services/Event';
import { convertQuestionFromApi } from 'services/Faq';

function SearchApi(executor: ApiExecutorType) {
  return {
    fetchArticlesByPage,
    fetchEventsPreview,
    fetchQuestions
  };

  function fetchArticlesByPage(page: number, per_page: number, input: string) {
    return executor
      .get(`/ffr/v1/posts?page=${page}&per_page=${per_page}&search=${input}`)
      .then(response => response.map(a => convertArticleFromApi(a)));
  }

  function fetchEventsPreview(input: string) {
    return executor
      .get(`/ffr/v1/events?search=${input}`)
      .then(response => response.events.map(event => convertEventPreviewFromApi(event)));
  }

  function fetchQuestions(input: string) {
    return executor
      .get(`/ffr/v1/wp_faq?search=${input}`)
      .then(response => response.map(questions => convertQuestionFromApi(questions)));
  }
}

export default SearchApi;

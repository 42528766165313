// @flow
import { getDateFromString } from 'utils/dateUtils';
import type { EventType, EventDetailDateType, EventPreviewType } from 'types/Event';

export function getEventsLabels(events: Array<EventType>) {
  return events
    .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    .reduce(
      (accumulator, currentValue) =>
        accumulator.find(label => label === currentValue.label) ? accumulator : [...accumulator, currentValue.label],
      []
    );
}

export function getEventDate(detail_date: EventDetailDateType) {
  const date: any = Object.keys(detail_date).reduce(
    (accumulator, key: string) => ({
      ...accumulator,
      [key]: parseInt(detail_date[key], 10)
    }),
    {}
  );
  return new Date(date.year, date.month, date.day, date.hour, date.minutes, date.seconds);
}

export function sortEventsByStartDateWithLabel(events: Array<EventType>, label: ?string) {
  return events
    .reduce((accumulator: Array<{ date: Date, events: Array<EventType> }>, currentValue: EventType) => {
      if (!label || label === currentValue.label) {
        const listWithCurrentDay = accumulator.find(
          l => getDateFromString(currentValue.startDate).getDate() === l.date.getDate()
        );
        if (listWithCurrentDay)
          return [
            ...accumulator.filter(l => l.date.getDate() !== getDateFromString(currentValue.startDate).getDate()),
            { date: getDateFromString(currentValue.startDate), events: [currentValue, ...listWithCurrentDay.events] }
          ];
        else return [...accumulator, { date: getDateFromString(currentValue.startDate), events: [currentValue] }];
      } else return accumulator;
    }, [])
    .sort((a, b) => {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return 0;
    });
}

export function sortEventsByTitleAndDate(events: Array<EventType>, filterLabel: ?string) {
  return events
    .sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      if (a.title === b.title) {
        const dateA = getDateFromString(a.startDate).getDate();
        const dateB = getDateFromString(b.startDate).getDate();

        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      }
      return 0;
    })
    .reduce((acc, event) => {
      const currentDate = getDateFromString(event.startDate).getDate();

      if (acc.length > 0 && acc[acc.length - 1].date === currentDate) {
        const lastEvents = acc[acc.length - 1];

        return [...acc.slice(0, acc.length - 1), { date: currentDate, events: [...lastEvents.events, event] }];
      }

      return [...acc, { date: currentDate, events: [event] }];
    }, []);
}

export function isFfrEvent(event: EventType | EventPreviewType) {
  const { import_type } = event;

  return (
    import_type && (import_type.toLowerCase() === 'ffr_rencontres' || import_type.toLowerCase() === 'ffr_evenements')
  );
}

// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { Link, withRouter } from 'react-router-dom';

export type StateProps = {
  query: string
};

export type DispatchProps = {
  setQuery: (query: string) => void
};

type Props = {
  children: any,
  history: any,
  location: any,
} & StateProps &
  DispatchProps;

type State = {
  open: boolean
};

class FilterOptions extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    open: false
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    if (query && query.get("s")) {
      this.props.setQuery(query.get('s'));
    }
  }

  componentDidUpdate() {
    const query = new URLSearchParams(this.props.location.search);
    const s = query && query.get("s");

    if (s !== this.props.query) {
      this.props.setQuery(query.get('s'));
    }
  }

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };

  handleInputChanged = (e: any) => {
    const query = e ? e.target.value.toLowerCase() : '';
    this.props.history.push(`/trouver-un-club-de-rugby/resultats-de-recherche?s=${query}`);
    this.props.setQuery(query);
  };

  render() {
    const classNamesFilterButton = dynamicClassName('btn btn--white filter-option__cta js-showFilter ft-up');
    this.state.open && classNamesFilterButton.add('is-active');

    return (
      <section className="filter ">
        <div className="filter__wrapper">
          <div className="input input--search " data-overlay="false">
            <input
              type="search"
              id="form_recherche_filtre"
              placeholder="Recherche"
              value={this.props.query || ''}
              onChange={this.handleInputChanged}
            />
            <label htmlFor="form_recherche_filtre">Recherche</label>
          </div>
          <div className="filter-option filter-option--mobile">
            <button
              className={classNamesFilterButton.build()}
              type="button"
              data-label={this.state.open ? 'Fermer' : 'Filtres'}
              onClick={this.toggleMenu}
            >
              {this.state.open ? 'Fermer' : 'Filtres'}
            </button>
            <div className="filter-option__list">
              {this.props.children}

              <div className="filter-option__action">
                <button className="btn btn--white">
                  <i className="icon icon-sync" />
                </button>
                <Link
                  to="#"
                  className="btn btn--primary js-showFilter"
                  onClick={this.toggleMenu}>
                  <i className="icon icon-valid is-inline" />
                  <span>Appliquer</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(FilterOptions);

// @flow
import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loading from 'components/fragments/Loading';

import type { ComponentType } from 'react';

const makeAsyncComponent = (componentImport: Function): $ReadOnly<Object> => {
  return Loadable({
    loader: componentImport,
    loading() {
      return <Loading />;
    }
  });
};

const Newsletter = makeAsyncComponent(() => import('containers/newsletter/NewsletterContainer'));
const NewsletterCategoryContainer = makeAsyncComponent(() =>
  import('containers/newsletter/NewsletterCategoryContainer')
);
const Article = makeAsyncComponent(() => import('containers/newsletter/ArticleContainer'));
const Club = makeAsyncComponent(() => import('containers/club/ClubContainer'));
const Billboard = makeAsyncComponent(() => import('containers/billboard/BillboardContainer'));
const ClubFinderContainer = makeAsyncComponent(() => import('containers/club/ClubFinderContainer'));
const ClubFinderResultContainer = makeAsyncComponent(() => import('containers/club/ClubFinderResultContainer'));
const PageListContainer = makeAsyncComponent(() => import('containers/page/PageListContainer'));
const PageNodeContainer = makeAsyncComponent(() => import('containers/page/PageNodeContainer'));
const PageNotFound = makeAsyncComponent(() => import('components/app/PageNotFound'));
const SocialWallContainer = makeAsyncComponent(() => import('containers/social/SocialWallContainer'));
const ContactWrapperContainer = makeAsyncComponent(() => import('containers/contact/ContactWrapperContainer'));
const FaqWrapperContainer = makeAsyncComponent(() => import('containers/contact/FaqWrapperContainer'));
const EventDetailContainer = makeAsyncComponent(() => import('containers/event/EventDetailContainer'));
const EventListContainer = makeAsyncComponent(() => import('containers/event/EventListContainer'));
const CompetitionListContainer = makeAsyncComponent(() => import('containers/competition/CompetitionListContainer'));
const CompetitionDetailContainer = makeAsyncComponent(() =>
  import('containers/competition/CompetitionDetailContainer')
);
const SearchWrapper = makeAsyncComponent(() => import('components/search/SearchWrapper'));
const PageStaticContainer = makeAsyncComponent(() => import('containers/page/PageStaticContainer'));

Billboard.preload();
Article.preload();

export function withProps(externalProps: Object) {
  return (WrapperComponent: ComponentType<Object>) => {
    return class HOSWrapperComponent extends React.PureComponent<Object> {
      render() {
        return <WrapperComponent {...this.props} {...externalProps} />;
      }
    };
  };
}

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Billboard} />
    <Route path="/actualites/:category/:slug" component={Article} />
    <Route exact path="/actualites" component={Newsletter} />
    <Route exact path="/actualites/:category" component={NewsletterCategoryContainer} />
    <Route exact path="/clubs/:slug" component={Club} />
    <Redirect from="/clubs" to="/trouver-un-club-de-rugby" />
    <Route exact path="/trouver-un-club-de-rugby" component={ClubFinderContainer} />
    <Route path="/trouver-un-club-de-rugby/resultats-de-recherche/" component={ClubFinderResultContainer} />
    <Route exact path="/social-wall" component={SocialWallContainer} />
    <Route exact path="/contact" component={ContactWrapperContainer} />
    <Route exact path="/contact/:slugTheme" component={ContactWrapperContainer} />
    <Route exact path="/contact/nous-contacter" component={withProps({ form: true })(ContactWrapperContainer)} />
    <Route exact path="/faq" component={FaqWrapperContainer} />
    <Route exact path="/faq/:slugTheme" component={FaqWrapperContainer} />
    <Route exact path="/faq/:slugTheme/:slugQuestion" component={FaqWrapperContainer} />
    <Route exact path="/recherche" component={SearchWrapper} />
    <Route exact path="/evenements/:slug" component={EventDetailContainer} />
    <Route path="/evenements/:year/:month" component={EventListContainer} />
    <Route path="/evenements" component={EventListContainer} />
    <Route exact path="/competitions" component={CompetitionListContainer} />
    <Route exact path="/competitions/:slug" component={CompetitionDetailContainer} />
    <Route
      exact
      path="/mentions-legales"
      component={withProps({ pageSlug: 'mentions-legales' })(PageStaticContainer)}
    />
    <Route exact path="/cgu" component={withProps({ pageSlug: 'cgu' })(PageStaticContainer)} />
    <Route
      exact
      path="/politique-de-cookies"
      component={withProps({ pageSlug: 'politique-de-cookies' })(PageStaticContainer)}
    />
    <Route path="/404" component={PageNotFound} />
    <Route exact path="/:slug" component={PageListContainer} />
    <Route path="/:slug/:slugNode" component={PageNodeContainer} />
    <Route component={PageNotFound} />
  </Switch>
);

export default Routes;

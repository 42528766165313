// @flow

import { mergeIntoDefault } from 'utils/objectUtils';

import { type RawPartenaireType, nullPartenaire } from 'types/Partenaires';

export const convertPartenaireFromApi = (rawPartenaire: RawPartenaireType) => {
  if (!rawPartenaire) {
    return nullPartenaire;
  }
  const {
    id,
    title: {
      rendered: title,
    },
    featured_media,
    content,
    excerpt,
    sidebar_content,
    meta: {
      lien,
      lien_cta,
      facebook,
      twitter,
      youtube,
      instagram,
    },
    meta_title,
    meta_description,
  } = rawPartenaire;


  return mergeIntoDefault(nullPartenaire, {
    id,
    title,
    featured_media,
    content,
    excerpt,
    lien,
    lien_cta,
    facebook,
    twitter,
    youtube,
    instagram,
    sidebar: sidebar_content ? sidebar_content.data : [],
    meta_title,
    meta_description,
  });
};

// @flow

import { takeLatest, put, call, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import PageApi from 'api/page/PageApi';
import { checkSidebar } from 'services/Sidebar';
import sidebarSaga from 'sagas/sidebarSaga';
import {
  FETCH_PAGE_LIST_START,
  pageListFetched,
  pageListFetchError,
  FETCH_PAGE_NODE_START,
  pageNodeFetched,
  pageNodeFetchError,
  FETCH_PAGE_DETAIL_START,
  pageDetailFetched,
  pageDetailFetchError,
  FetchUnknown
} from 'actions/pageActions';

export default function(apiExecutor: ApiExecutorType) {
  const pageApi = new PageApi(apiExecutor);

  return function* pageListSaga(): GeneratorType {
    yield takeLatest(FETCH_PAGE_LIST_START, fetchPageList);
    yield takeLatest(FETCH_PAGE_NODE_START, fetchPageNode);
    yield takeLatest(FETCH_PAGE_DETAIL_START, fetchPageDetail);
  };

  function* fetchPageList(action): Saga<void> {
    try {
      const { slug } = action.payload;
      const pageList = yield call(pageApi.fetchPageList, slug);

      if (pageList.niveau === 1) {
        yield put(pageListFetched(pageList));
      } else {
        yield put(FetchUnknown());
      }
    } catch (e) {
      yield put(pageListFetchError());
    }
  }

  function* fetchPageNode(action): Saga<void> {
    try {
      const { slug } = action.payload;
      const pageNode = yield call(pageApi.fetchPageNode, slug);

      const check = checkSidebar(pageNode.sidebar_content);

      if (!check) {
        const state = yield select();

        if (state.sidebarState.data.length === 0) {
          yield call(sidebarSaga(apiExecutor).fetchGenericSidebar);
          const state2 = yield select();
          pageNode.sidebar_content = state2.sidebarState.data;
        } else {
          pageNode.sidebar_content = state.sidebarState.data;
        }
      }

      if (pageNode.niveau === 2) {
        yield put(pageNodeFetched(pageNode));
      } else {
        yield put(FetchUnknown());
      }
    } catch (e) {
      yield put(pageNodeFetchError());
    }
  }

  function* fetchPageDetail(action): Saga<void> {
    try {
      const { slug } = action.payload;
      const pageDetail = yield call(pageApi.fetchPageDetail, slug);

      if (pageDetail.niveau === 3) {
        yield put(pageDetailFetched(pageDetail, true));
      } else {
        yield put(FetchUnknown());
      }
    } catch (e) {
      yield put(pageDetailFetchError());
    }
  }
}

// @flow
import React from 'react';
import { handleTagEvent }from 'utils/tagManagerUtils';
import type { liensRsType } from 'types/Settings';
import NewsletterSubscriberContainer from 'containers/footer/NewsletterSubscriberContainer';

export type StateProps = {
  titleLigue: string,
  liens_rs: liensRsType
};

const FooterTop = (props: StateProps) => {
  const {
    liens_rs: {
      fb_lien, 
      twitter_lien,
      instagram_lien,
      youtube_lien,
      flickr_lien
    },
    titleLigue
  } = props; 
  if (fb_lien === '' && twitter_lien === '' && instagram_lien === '' && youtube_lien === '' && flickr_lien === '') {
    return null;
  }
  return (
    <div className="container footer__top">
      <div className="row">
        <div className="col col-lg-3">
          <h5 className="mb-1">Nous suivre</h5>
          <div className="mt-3 mb-5">
            {
              props.liens_rs.fb_lien &&
              <a
                className="link-icon is-dark"
                href={props.liens_rs.fb_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Facebook de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'facebook')}>
                <i className="icon icon-facebook" />
              </a>
            }
            {
              props.liens_rs.twitter_lien &&
              <a
                className="link-icon is-dark"
                href={props.liens_rs.twitter_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Twitter de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'twitter')}>
                <i className="icon icon-twitter" />
              </a>
            }
            {
              props.liens_rs.instagram_lien &&
              <a
                className="link-icon is-dark"
                href={props.liens_rs.instagram_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Instagram de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'instagram')}>
                <i className="icon icon-instagram" />
              </a>
            }
            {
              props.liens_rs.youtube_lien &&
              <a
                className="link-icon is-dark"
                href={props.liens_rs.youtube_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Youtube de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'youtube')}>
                <i className="icon icon-youtube" />
              </a>
            }
            {
              props.liens_rs.flickr_lien &&
              <a
                className="link-icon is-dark"
                href={props.liens_rs.flickr_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Flickr de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'flickr')}>
                <i className="icon icon-flickr" />
              </a>
            }
          </div>
        </div>
        <NewsletterSubscriberContainer />
      </div>
      <hr />
    </div>
  );
};

export default FooterTop;

// @flow
import React, { PureComponent } from 'react';
import Routes from './../../routes';

import { withHelmet } from 'infrastructure/seo/Helmet';
import Partners from 'containers/footer/PartnersContainer';
import CookieContainer from 'containers/footer/CookieContainer';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import type { FaviconType } from 'types/Settings';
import ModalContainer from 'containers/fragments/ModalContainer';

import { footerBlacklistPaths } from 'constants/menuConstants';

type HistoryProps = {
  history: any
};

export type StateProps = {
  favicons: FaviconType,
  titleLigue: string
};

export type DispatchProps = {
  fetchSettings: () => void,
  fetchMenu: () => void,
  fetchCategories: () => void,
  fetchPartners: () => void,
  keycloakConnect: () => void
};

type Props = {
  location: any
} & DispatchProps &
  StateProps &
  HistoryProps;

class App extends PureComponent<Props> {
  componentDidMount() {
    this.props.fetchSettings();
    this.props.fetchMenu();
    this.props.fetchCategories();
    this.props.fetchPartners();
    this.props.keycloakConnect();
  }

  componentDidUpdate() {
    const { pathname } = this.props.location;
    const splited = pathname.split('/');

    if (splited[1] === 'faq' || splited[1] === 'contact') {
      if (!splited[2]) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 250);
      }
    } else if (splited[1] === 'actualites') {
      window.scrollTo(0, 0);
    } else if (!splited[3]) {
      window.scrollTo(0, 0);
    }
  }

  renderFooter() {
    if (footerBlacklistPaths.includes(this.props.location.pathname)) return null;
    return (
      <React.Fragment>
        <Partners />
        <Footer />
      </React.Fragment>
    );
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'production') {
      this.props.history.push('/404');
      window.location.reload();
    }
  }

  render() {
    return (
      <>
        <ModalContainer />
        <Header />

        <Routes />
        <CookieContainer />
        {this.renderFooter()}
      </>
    );
  }
}

// [SEO] Set default meta tags here !
function renderHelmetDefault(Helmet, pageProps) {
  return (
    <Helmet titleTemplate={`%s`} defaultTitle={pageProps.titleLigue ? pageProps.titleLigue : window.location.href}>
      <link rel="shortcut icon" href={pageProps.favicons['mstile-150']} />
      <link rel="apple-touch-icon" sizes="180x180" href={pageProps.favicons['favicon-180']} />
      <link rel="icon" type="image/png" sizes="32x32" href={pageProps.favicons['favicon-32']} />
      <link rel="icon" type="image/png" sizes="16x16" href={pageProps.favicons['favicon-16']} />
      <meta property={'og:site_name'} content={pageProps.titleLigue || "Compétitions - FFR"} />
    </Helmet>
  );
}

export default withHelmet(App)(renderHelmetDefault);

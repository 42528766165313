// @flow
import { combineReducers } from 'redux';
import articleReducer from 'reducers/articleReducer';
import appReducer from 'reducers/appReducer';
import eventsPreviewReducer from 'reducers/eventsPreviewReducer';
import tagsReducer from 'reducers/tagsReducer';
import matchResultReducer from 'reducers/matchResultReducer';
import instagramReducer from 'reducers/instagramReducer';
import twitterReducer from 'reducers/twitterReducer';
import facebookReducer from 'reducers/facebookReducer';
import newsletterReducer from 'reducers/newsletterReducer';
import menuReducer from 'reducers/menuReducer';
import clubsReducer from 'reducers/clubsReducer';
import clubReducer from 'reducers/clubReducer';
import competitionsReducer from 'reducers/competitionsReducer';
import eventReducer from 'reducers/eventReducer';
import filterOptionsReducer from 'reducers/filterOptionsReducer';
import categoriesReducer from 'reducers/categoriesReducer';
import newsletterCategoryReducer from 'reducers/newsletterCategoryReducer';
import eventsReducer from 'reducers/eventsReducer';
import partnersReducer from 'reducers/partnersReducer';
import settingsReducer from 'reducers/settingsReducer';
import cardReducer from 'reducers/cardReducer';
import pageListReducer from 'reducers/pageListReducer';
import pageNodeReducer from 'reducers/pageNodeReducer';
import pageDetailReducer from 'reducers/pageDetailReducer';
import sidebarReducer from 'reducers/sidebarReducer';
import competitionReducer from 'reducers/competitionReducer';
import faqReducer from 'reducers/faqReducer';
import searchReducer from 'reducers/searchReducer';
import newsletterSubscriptionReducer from 'reducers/newsletterSubscriptionReducer';
import modalReducer from 'reducers/modalReducer';
import youtubeReducer from 'reducers/youtubeReducer';
import PartenaireReducer from 'reducers/PartenaireReducer';
import JobDetailReducer from 'reducers/jobDetailReducer';
import JobListReducer from 'reducers/jobListReducer';
import contactForm7Reducer from 'reducers/contactForm7Reducer';
import userReducer from 'reducers/userReducer';

export default combineReducers({
  articleState: articleReducer,
  appState: appReducer,
  eventsPreviewState: eventsPreviewReducer,
  tagsState: tagsReducer,
  matchResultState: matchResultReducer,
  instagramState: instagramReducer,
  facebookState: facebookReducer,
  twitterState: twitterReducer,
  newsletterState: newsletterReducer,
  menuState: menuReducer,
  clubsState: clubsReducer,
  clubState: clubReducer,
  competitionsState: competitionsReducer,
  eventState: eventReducer,
  filterOptionsState: filterOptionsReducer,
  categoriesState: categoriesReducer,
  newsletterCategoryState: newsletterCategoryReducer,
  eventsState: eventsReducer,
  partnersState: partnersReducer,
  settingsState: settingsReducer,
  cardState: cardReducer,
  pageListState: pageListReducer,
  pageNodeState: pageNodeReducer,
  pageDetailState: pageDetailReducer,
  sidebarState: sidebarReducer,
  competitionState: competitionReducer,
  faqState: faqReducer,
  searchState: searchReducer,
  newsletterSubscriptionState: newsletterSubscriptionReducer,
  modalState: modalReducer,
  youtubeState: youtubeReducer,
  partenaireState: PartenaireReducer,
  jobDetailState: JobDetailReducer,
  jobListState: JobListReducer,
  contactForm7State: contactForm7Reducer,
  userState: userReducer,
});

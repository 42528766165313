import 'react-app-polyfill/ie11';
import 'core-js/es6/map';
import 'core-js/es6/set';

import React from 'react';
import ReactDOM from 'react-dom';
import { initWindowApp } from 'utils/windowUtils';
import registerServiceWorker from './registerServiceWorker';
import App from 'components/app/AppProvider';
import TagManager from 'react-gtm-module';

import 'ie-array-find-polyfill';
import 'raf/polyfill';
import 'utils/ieUtils';
import './polyfill-includes';
import 'assets/css/style.css';
import 'assets/css/custom.css';

const tagManagerArgs = {
  gtmId: 'GTM-5MQV3GK',
  dataLayerName: 'dataLayer'
};

TagManager.initialize(tagManagerArgs);
initWindowApp();

const root = document.getElementById('root');
ReactDOM.render(<App />, root);

registerServiceWorker();

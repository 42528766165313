/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
  id: string,
  options: Array<{ label: string, value: string }>,
  label: string,
  counter: number,
  inputChanged: (value: string) => void,
  selected: Array<string>
};

type State = {
  isOpen: boolean,
};

class DropdownInput extends PureComponent<Props, State> {
  props: Props;
  wrapperRef: any;

  static defaultProps = {
    id: 'ID',
    label: 'Label',
    options: [
      {
        value: 'option1',
        label: 'option1'
      }
    ],
    counter: 0
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { isOpen } = this.state;
    const classNames = dynamicClassName('dropdown dropdown--accordion mb-3');
    isOpen && classNames.add('is-open');
    this.props.selected.length > 0 && classNames.add('has-counter');
    return (
      <div className={classNames.build()} ref={this.setWrapperRef}>
        <span className="dropdown__head ft-h6 ft-up" onClick={this.handleClickDropDown}>
          <span className="dropdown__label">{this.props.label}</span>
          <span className="dropdown__counter">({this.props.selected.length})</span>
        </span>
        <div className="dropdown__list">
          <span className="dropdown__head ft-h6 ft-up" onClick={this.handleClickDropDown}>
            <span className="dropdown__label">{this.props.label}</span>
            <span className="dropdown__counter">({this.props.selected.length})</span>
          </span>
          <div className="dropdown__wrapper">{this.renderOptions(this.props.options)}</div>
        </div>
      </div>
    );
  }

  renderOptions = (options: any) => {
    return options.map((option, index) => (
      <div className="checkbox" key={index}>
        <input
          type="checkbox"
          id={this.props.id + index}
          onChange={this.handleSelected(option.value)}
          checked={this.props.selected.includes(option.value)}
        />
        <label htmlFor={this.props.id + index}>
          {option.value}
        </label>
      </div>
    ));
  };

  handleClickDropDown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  handleSelected = (value: string) => {
    return () => {
      this.props.inputChanged(value);
    };
  };
}

export default DropdownInput;

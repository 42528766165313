// @flow
import TagManager from 'react-gtm-module';
import React, { PureComponent } from 'react';

import DropdownInput from 'components/fragments/input/DropdownInput';
import DropdownRadioInput from 'components/fragments/input/DropdownRadioInput';
import FilterOptionsContainer from 'containers/header/FilterOptionsContainer';
import { CLUB_FILTER_DISTANCES } from 'constants/clubConstants';

const distances = [
  { value: '', label: 'Tout' },
  ...CLUB_FILTER_DISTANCES.map(p => ({ value: p, label: `${p}km` })),
];

export type StateProps = {
  competitions: { Competitions: Array<Object> },
  selectedCompetitions: Array<string>,
  practices: Array<string>,
  selectedPractices: Array<string>,
  activateDistance: boolean,
  ligue: number,
  acceptCookie: boolean,
};

export type DispatchProps = {
  getCompetitions: (ligue: number) => void,
  setPractices: (practice: Array<string>) => void,
  setDistance: (distance: string) => void,
  setCompetitions: (competition: Array<string>) => void
};

type Props = StateProps & DispatchProps;

type State = {
  open: boolean,
  distances: Array<{ value: string, label: string }>
};

function sortCompetition(a, b) {
  if (a.StructureOrganisatrice.nom === 'FFR') {
    if (b.StructureOrganisatrice.nom === 'FFR') {
      return a.nom < b.nom ? -1 : a.nom > b.nom ? 1 : 0;
    }

    return -1;
  } else if (b.StructureOrganisatrice.nom === 'FFR') {
    return 1;
  }

  return a.nom < b.nom ? -1 : a.nom > b.nom ? 1 : 0;
}

class HeaderFindClub extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    open: false,
    distances: [{ value: '', label: 'Tout' }]
  };

  setPractice = (practice: string) => {
    if (this.props.selectedPractices.includes(practice))
      this.props.setPractices(this.props.selectedPractices.filter(p => p !== practice));
    else {
      if (this.props.acceptCookie) {
        TagManager.dataLayer({
          dataLayer: {
            'event': 'eventGA',
            'eventCategory': 'recherche geolocalisee',
            'eventAction': 'ajout filtre type de rugby',
            'eventLabel': practice
          },
          dataLayerName: 'dataLayer'
        });
      }

      this.props.setPractices([...this.props.practices, practice]);
    };
  };

  setDistance = (distance: string) => {
    if (this.props.acceptCookie) {
      TagManager.dataLayer({
        dataLayer: {
          'event': 'eventGA',
          'eventCategory': 'recherche geolocalisee',
          'eventAction': 'ajout filtre distance',
          'eventLabel': `${distance}km`
        },
        dataLayerName: 'dataLayer'
      });
    }

    this.props.setDistance(distance);
  };

  setCompetition = (competition: string) => {
    if (this.props.selectedCompetitions.includes(competition))
      this.props.setCompetitions(this.props.selectedCompetitions.filter(c => c !== competition));
    else {
      if (this.props.acceptCookie) {
        TagManager.dataLayer({
          dataLayer: {
            'event': 'eventGA',
            'eventCategory': 'recherche geolocalisee',
            'eventAction': 'ajout filtre competition',
            'eventLabel': competition
          },
          dataLayerName: 'dataLayer'
        });
      }
      this.props.setCompetitions([...this.props.selectedCompetitions, competition]);
    };
  };

  componentDidMount() {
    this.props.setDistance('');
    this.props.setCompetitions([]);

    if (this.props.ligue) {
      this.props.getCompetitions(this.props.ligue);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.ligue && this.props.ligue !== prevProps.ligue) {
      this.props.getCompetitions(this.props.ligue);
    }
  }

  render() {
    const {
      activateDistance,
      practices,
      selectedPractices,
      competitions: { Competitions },
      selectedCompetitions
    } = this.props;

    return (
      <FilterOptionsContainer>
        <DropdownInput
          label="Types de rugby"
          counter={0}
          id="Types de rubgy"
          options={practices.map((p) => ({ value: p, label: p }))}
          inputChanged={this.setPractice}
          selected={selectedPractices}
        />
        <DropdownInput
          label="Compétitions"
          counter={0}
          id="Compétitions"
          options={Competitions.sort(sortCompetition).map(({ nom }) => ({ value: nom, label: nom }))}
          inputChanged={this.setCompetition}
          selected={selectedCompetitions}
        />
        <DropdownRadioInput
          prelabel="Distance : "
          isPreLabelThin={false}
          counter={0}
          id="Distance"
          options={distances}
          inputChanged={this.setDistance}
          maj={true}
          title={true}
          isActive={activateDistance}
        />
      </FilterOptionsContainer>
    );
  }
}

export default HeaderFindClub;

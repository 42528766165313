// @flow
import App from 'components/app/App';
import { connect } from 'react-redux';
import { fetchSettings } from 'actions/appActions';
import { fetchMenu } from 'actions/menuActions';
import { fetchPartners } from 'actions/partnerActions';
import { fetchCategories } from 'actions/categoriesActions';
import { keycloakConnect } from 'actions/userActions';

import type { StateProps, DispatchProps } from 'components/app/App';
import type { StateType, DispatchType } from 'types/Actions';
import { withRouter } from 'react-router-dom';

export default withRouter(
  connect(
    (state: StateType): StateProps => ({
      favicons: state.settingsState.favicons,
      titleLigue: state.settingsState.title,
    }),
    (dispatch: DispatchType): DispatchProps => ({
      fetchSettings: () => dispatch(fetchSettings()),
      fetchMenu: () => dispatch(fetchMenu()),
      fetchCategories: () => dispatch(fetchCategories()),
      fetchPartners: () => dispatch(fetchPartners()),
      keycloakConnect: () => dispatch(keycloakConnect()),
    })
  )(App)
);

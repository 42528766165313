// @flow
import React, { PureComponent } from 'react';

import Label from 'components/fragments/text/Label';
import { getConfSso } from 'constants/sso';
import type { MenuElementType } from 'types/MenuElement';
import type { RouterProps } from 'types/Router';
import { dynamicClassName } from 'utils/dynamicClassName';
import { handleTagEvent } from 'utils/tagManagerUtils';

import userAvatar from './placeholder-profil.png';
import TopBarDropdown from './TopBarDropdown';

type StateProps = {
  connectionInProgress: boolean,
  keycloakData: any,
  topBarElements: MenuElementType[];
  topNews:{
    title: string,
    link: string,
    label: string,
  }
};

type Props = StateProps & RouterProps & {
  authenticated: boolean,
};

type State = {
  isAccordionOpen: boolean,
  loggedIn: boolean,
};

class TopBar extends PureComponent<Props, State> {
  static defaultProps = {
    topBarElements: []
  };

  state = {
    isAccordionOpen: false,
    loggedIn: false,
  }; 

  wrapperRef: any;

  static getDerivedStateFromProps(props: Props, state: State) {
    const { keycloakData } = props;
    let newState = {};

    if (keycloakData) {
      newState.loggedIn = keycloakData.authenticated;
    };

    if (newState.loggedIn || newState.loggedIn !== state.loggedIn) {
      return newState;
    };

    return null;
  }

  onLinkClick = (link: string) => {
    return handleTagEvent('menu_transverse', 'clic_lien_sortant', link);
  }

  renderTopBarElements = () => {
    const { topBarElements } = this.props;
    const menus = topBarElements.filter(item => item.parent === 0);

    return menus.map((menu, index) => {
      if (menu.items && menu.items.length > 0) {
        return <TopBarDropdown key={index} subMenus={menu.items} title={menu.title} />;
      }

      return (
        <li key={index}>
          <a
            href={menu.url}
            title={`Se rendre sur le site officiel "${menu.title}" de la Fédération Française de Rugby (nouvel onglet)`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.onLinkClick(menu.url)}
          >
            {menu.title}
          </a>
        </li>
      );
    });
  };

  handleAccordionOpen = () => {
    this.setState((prevState) => (
      { isAccordionOpen: !prevState.isAccordionOpen}
    ));
  };

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  render() {
    const {
      authenticated,
      connectionInProgress,
      keycloakData: {
        keycloak,
      },
      topNews
    } = this.props;
    const { loggedIn, isAccordionOpen } = this.state;

    // PRIVATE ACCOUNT IDENTIFICATION
    const confSso = getConfSso();
    const keycloakLogo = !!keycloak && keycloak.tokenParsed && keycloak.tokenParsed.logo
    ? keycloak.tokenParsed.logo : '';
    const prenom = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.given_name : '';
    const nom = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.family_name : '';

    // CLASSES
    const accordionTriggerDynamicClasses = dynamicClassName('accordion-trigger topbar__user');
    const topbarDynamicClasses = dynamicClassName('topbar');
    const accordionDynamicClasses = dynamicClassName('topbar__sub accordion-panel');

    if (isAccordionOpen) {
      topbarDynamicClasses.add('has-accordion-open');
      accordionDynamicClasses.remove('is-hidden');
      accordionTriggerDynamicClasses.add('is-selected');
    } else {
      topbarDynamicClasses.remove('has-accordion-open');
      accordionDynamicClasses.add('is-hidden');
      accordionTriggerDynamicClasses.remove('is-selected');
    };
  
    return (
      <nav className="topbar">
        <ul className="topbar__left">
          {this.renderTopBarElements()}
          
          {topNews.title && topNews.link &&
          <>
            <li className="topbar__news">
              <a href={topNews.link} target="_blank" rel="noopener noreferrer">
                <Label isSmall isBlueAlt label={topNews.label} />
                <span>{topNews.title}</span>
              </a>
            </li>
          </>}

          {
            // PRIVATE ACCOUNT IDENTIFICATION
            // non-autenticated user button
            (!connectionInProgress && !loggedIn && !authenticated) ? (
              <li className="topbar__right">
                <a 
                  className="topbar__login"
                  href={keycloak && keycloak.createLoginUrl({redirectUri: confSso.urlEspacePrive})}
                  title="Accéder à votre espace personnel">
                  Connexion / inscription
                </a>
              </li>
            ) : 
            // autenticated user button
            !connectionInProgress &&  ( 
              <li className="topbar__right">
                <a
                  onClick={this.handleAccordionOpen}
                  className={accordionTriggerDynamicClasses.build()}
                  ref={this.setWrapperRef}
                  role='button'
                  tabIndex='0'
                >
                  {keycloakLogo && <img src={keycloakLogo} alt="" style={{ width: '24px', height: '24px' }} />}
                  {!keycloakLogo && <img src={userAvatar} alt="" style={{ width: '24px', height: '24px' }} />}
                  {`${prenom ?? ''} ${nom ?? ''}`}
                </a>
                <ul className={accordionDynamicClasses.build()} id="accordion-user">
                  <li>
                    <a
                      href={confSso.urlEspacePrive}
                      className='accordion-user-not-close'
                      onClick={() => this.setState({ isAccordionOpen: false })}
                    >
                      <i className="icon icon-account is-inline" />
                        Espace personnel
                    </a>
                  </li>
                  <li>
                    <a href={keycloak.createLogoutUrl()}
                      className='accordion-user-not-close'>
                      <i className="icon icon-logout is-inline" />
                      Se déconnecter
                    </a>
                  </li>
                </ul>
              </li>
            )
          }
        </ul>
      </nav>
    );
  }
}

export default TopBar;
